import React, {Component} from "react";
const classes = require('./Form.module.scss');
const styles = require('../../hoc/Contact/Contact.module.scss');

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    let styleParent = classes;
    if (this.props.FormType === 'ContactPage') {
      styleParent = styles;
    }

    const { status } = this.state;
    
    return (
      <div className={[styleParent.cFormWrap, styleParent[this.props.FormType]].join(' ')}>
        <form
          className={styleParent.contactForm}
          onSubmit={this.submitForm}
          action="https://formspree.io/f/mbjpkjao"
          method="POST"
        >

          <div className={styleParent.customer}>
            <h4>Customer Information</h4>
            <div className={styleParent.titleLine}/><div className={styleParent.clearfix}/>

            <div className={[styleParent.formGroup, styleParent.fgname].join(' ')}>
              <label>Full Name</label>
              <input className={styleParent.cfield} type="text" name="name" />
            </div>
              
            <div className={[styleParent.formGroup, styleParent.fgemail].join(' ')}>
              <label>Email</label>
              <input className={styleParent.cfield} type="email" name="_replyto" />
            </div>

            <div className={[styleParent.formGroup, styleParent.fgphone].join(' ')}>
              <label>Phone</label>
              <input className={styleParent.cfield} type="text" name="phone" />
            </div>
          </div>

          <div className={styleParent.property}>
            <h4>Property Information</h4>
            <div className={styleParent.titleLine}/><div className={styleParent.clearfix}/>

            <div className={[styleParent.formGroup, styleParent.fgtype].join(' ')}>
              <label>Property Type</label>
              <select className={styleParent.cfield} name="type" id="type" required="">
                  <option>Select</option>
                  <option value="Home">Home</option>
                  <option value="Business">Business</option>
              </select>
            </div>
              
            <div className={[styleParent.formGroup, styleParent.fgaddress].join(' ')}>
              <label>Address of Location</label>
              <input className={styleParent.cfield} type="text" name="address" />
            </div>

            <div className={[styleParent.formGroup, styleParent.fgsize].join(' ')}>
              <label>Sq. Footage</label>
              <input className={styleParent.cfield} type="text" name="size" />
            </div>

            <div className={[styleParent.formGroup, styleParent.fgdets].join(' ')}>
              <label className={styleParent.h4Label}>Give us some details about your problem.</label>
              <div className={styleParent.clearfix}/><div className={styleParent.titleLine}/><div className={styleParent.clearfix}/>
              <textarea className={styleParent.cfield} name="details"></textarea>
            </div>
          </div>

          {status === "SUCCESS" ? <p className={classes.successSub}>Thanks for reaching out. A member of our team will contact you soon.</p> : <div className={[styleParent.formGroup, styleParent.btn].join(' ')}><button className={[styleParent.gcmLink, styleParent.submit].join(' ')}>Submit</button></div>}
          {status === "ERROR" && <p className={classes.errorSub}>Ooops! There was an error.</p>}
        </form>
      </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
