import React from "react";
// import { Link } from "react-router-dom";
const classes = require('./CertificationSlide.module.scss');
const certIAC2 = require('../../assets/images/cert-IAC2.png');
const certCRMI = require('../../assets/images/cert-CRMI.jpg');
const certAQ = require('../../assets/images/cert-airQualitySq.png');
const certIAQA = require('../../assets/images/cert-IAQA.jpg');
const certINFR = require('../../assets/images/cert-infrared2.png');
const certMSTR = require('../../assets/images/cert-moisture.png');
const certNAMRI = require('../../assets/images/cert-NAMRI.jpg');

const ac2 = (
  <div className={[classes.slideLogo, classes.slideOff, classes.ac2].join(' ')}>
    <div className={classes.inner}><img src={certIAC2} alt="International Association of Certified Indoor Air Consultants logo" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
  </div>
);

const aq = (
  <div className={[classes.slideLogo, classes.hvrBobx, classes.aq].join(' ')}>
    <div className={classes.inner}><img src={certAQ} alt="Internachi Certified Indoor Air Quality Inspectors logo" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
  </div>
);

const mstr = (
  <div className={[classes.slideLogo, classes.slideOff, classes.mstr].join(' ')}>
    <div className={classes.inner}><img src={certMSTR} alt="Internachi Certified Moisture Intrusion Inspector logo" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
  </div>
);

const infr = (
  <div className={[classes.slideLogo, classes.hvrBobx, classes.infr].join(' ')}>
    <div className={classes.inner}><img src={certINFR} alt="Gulf Coast Mold Detection is Infrared Certified" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
  </div>
);


const certificationSlide = (props) => {
  return (
    <div className={[classes.CertificationSlide, classes[props.Section]].join(' ')}>
      <div className={classes.styleIL}>
        {/* <div className={classes.insideXl}> */}
          <div className={[classes.slideLogo, classes.slideOff, classes.ac2].join(' ')}>
            <div className={classes.inner}>
              <img src={certIAC2} alt="International Association of Certified Indoor Air Consultants logo" className={[classes.imgResponsive, classes.certImg].join(' ')} />
            </div>
          </div>

          <div className={[classes.slideLogo, classes.hvrBobx, classes.aq].join(' ')}>
            <div className={classes.inner}><img src={certAQ} alt="Internachi Certified Indoor Air Quality Inspectors logo" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
          </div>
          
          <div className={[classes.slideLogo, classes.hvrBobx, classes.iaqa].join(' ')}>
            <div className={classes.inner}><img src={certIAQA} alt="Gulf Coast Mold Detection is an Indoor Air Quality Association member" className={[classes.imgResponsive, classes.certImg, classes.iaqa].join(' ')} /></div>
          </div>
    
          <div className={[classes.slideLogo, classes.crmi].join(' ')}>
            <div className={classes.inner}><img src={certCRMI} alt="alt ph" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
          </div>

          <div className={[classes.slideLogo, classes.hvrBobx, classes.infr].join(' ')}>
            <div className={classes.inner}><img src={certINFR} alt="Gulf Coast Mold Detection is Infrared Certified" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
          </div>

          <div className={[classes.slideLogo, classes.slideOff, classes.mstr].join(' ')}>
            <div className={classes.inner}><img src={certMSTR} alt="Internachi Certified Moisture Intrusion Inspector logo" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
          </div>

          <div className={[classes.slideLogo, classes.namri].join(' ')}>
            <div className={classes.inner}><img src={certNAMRI} alt="National Association of Remediators and Inspectors logo" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
          </div>
        {/* </div> */}
      </div>

      <div className={classes.styleOG2}>
        <div className={classes.insideAlt}>
          <div className={classes.midRow}><p>More than 15 years<br/> of experience, and thousands <br/>of insurance claims!</p></div>
  
          <div className={classes.logoRowLeft}>
            {ac2}
            {aq}
          </div>
            
          <div className={classes.logoRowRight}>
            {mstr}
            {infr}
          </div>  

          <div className={classes.logoRowBtm}>
            {ac2}
            {aq}
            {mstr}
            {infr}
            <div className={[classes.slideLogo, classes.hvrBobx, classes.iaqa].join(' ')}>
              <div className={classes.inner}><img src={certIAQA} alt="Gulf Coast Mold Detection is an Indoor Air Quality Association member" className={[classes.imgResponsive, classes.certImg, classes.iaqa].join(' ')} /></div>
            </div>

            <div className={[classes.slideLogo, classes.crmi].join(' ')}>
              <div className={classes.inner}><img src={certCRMI} alt="alt ph" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
            </div>

            <div className={[classes.slideLogo, classes.namri].join(' ')}>
              <div className={classes.inner}><img src={certNAMRI} alt="National Association of Remediators and Inspectors logo" className={[classes.imgResponsive, classes.certImg].join(' ')} /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default certificationSlide;