import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import Form from "../../components/Form/Form";
const classes = require('./Contact.module.scss');
const image = require('../../assets/images/mold-bin.jpg');

const contact = () => {
  return (
    <div className={classes.Contact}>
      <Helmet>
        <title>Contact Gulf Coast Mold Detection</title>
        <meta name="description" content="If you need a certified, professional mold inspection along the Gulf Coast of Alabama or Florida, contact Gulf Coast Mold Detection today." />
      </Helmet>

      {/* Gulf Coast Mold Detection offers mold inspection services throughout the areas of  <span className={classes.emphLong}>Baldwin County </span> 
        and <span className={classes.emphSmall}>Mobile, AL</span> <br/> and <span className={classes.emph}>Pensacola, FL</span>.*/}
      <div className={[classes.PageHeader, classes.ContactHd].join(' ')}>
        <div className={classes.med2Def}> 
          <h1>Are you interested in <span className={classes.emph}> hiring </span><br/> Gulf Coast Mold Detection, 
          or learning more about our <span className={classes.emphLong}>mold detection</span> services?
          </h1>
          
          <h2>Fill out the form below, or contact us <span className={classes.emph}> today.</span> <br/> 
            You can also check our <Link to="/faq" className={classes.textLink}>FAQ's</Link> for more information.</h2>
        </div>
      </div>

      <div className={classes.cInfo}>
        <div className={classes.medDef}>
          <div className={[classes.ciBox, classes.box1].join(' ')}>
            <p className={classes.cat}>Phone:</p>
            <p className={classes.info}><Phone /></p>
          </div>
      
          <div className={[classes.ciBox, classes.box2].join(' ')}>
            <p className={classes.cat}>Email:</p>
            <p className={classes.info}><Email /></p>
          </div>

          <div className={[classes.ciBox, classes.box4].join(' ')}>
            <p className={classes.cat}>Follow:</p>
            <a className={[classes.info, classes.fb].join(' ')} href="https://www.facebook.com/GulfCoastMoldDetection" target="_blank" rel="noopener noreferrer">
              <span className={classes.SocialIcon} aria-label="Gulf Coast Mold Detection Facebook Link" uk-icon="icon: facebook"></span>
            </a>
          </div>

          <div className={[classes.ciBox, classes.box3].join(' ')}>
            <p className={classes.cat}>Hours:</p>
            <p className={classes.info}>
            <b>Monday - Friday:</b> 8am - 5pm<br/> 
            <b> Saturday:</b> 10am - 4pm</p>
          </div>
        </div>
      </div>

      <div className={classes.cForm} id="fix-wrap" data-uk-grid>
        <div className={classes.insideTw}>
          <div className={classes.cfInner}><Form FormType="ContactPage" /></div>
          <div className={[classes.cfImage, classes.withTextX].join(' ')}>
            <img src={image} alt="ph alt" className={[classes.imgResponsive, classes.imgRel].join(' ')} />
            <div className={classes.imgFix} data-uk-sticky="offset:50; bottom: #fix-wrap;"><img src={image} alt="Mold found behind storage bin" className={classes.imgResponsive} /></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default contact;