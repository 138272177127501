import React from "react";
import Form from "../Form/Form";
const classes = require('./RequestModal.module.scss');

const requestModal = () => {
  return (
    <div id="modal-request" className={[classes.requestModal, "uk-flex-top"].join(' ')} data-uk-modal>
      <div className={[classes.reqModal, "uk-modal-dialog uk-modal-body uk-margin-auto-vertical"].join(' ')}>{/* data-uk-overflow-auto */}
        <div className={classes.modalBod}>
          <button className="uk-modal-close-default" type="button" data-uk-close></button>
          <div className={classes.modalHd}>
            <p className={classes.modalLead}>Are you interested in hiring Gulf Coast Mold Detection?</p>
            <p>Fill out the form below, and someone from our team will contact you soon!</p>
          </div>
          <Form FormType="ModalForm" />
        </div>
      </div>
    </div>
  );
}

export default requestModal;


