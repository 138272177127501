import React from "react";
import { Helmet } from "react-helmet";
const classes = require('./Health.module.scss');
const wall = require('../../assets/images/wallpaper-mold.jpg');

const health = () => {
  return (
    <div className={classes.Health}>
      <Helmet>
        <title>Mold and Your Health Problems | Gulf Coast Mold Detection</title>
        <meta name="description" content="Gulf Coast Mold Detection wants you to understand the effects that mold exposure has on your health. Contact us today for a professional mold inspection." />
      </Helmet>


      <div className={[classes.PageHeader, classes.HealthHd].join(' ')}>
        <div className={classes.medDef}>
          <h1>
            <span className={classes.emphLong}>Mold and moisture</span> could be responsible for your health problems. 
            Call <b>Gulf Coast Mold Detection<br/> <span className={classes.emphCustom}> today</span></b> to schedule a <span className={classes.emph}>professional</span> mold inspection.
          </h1>
          <h2>If you, or someone you know, is experiencing any of the symptoms listed on this page, and you can’t determine the cause, 
            <b className={classes.b1}> give us a call today!</b>
          </h2>
        </div>
      </div>

      <div className={classes.intro}>
        <div className={classes.insideTw}>
          <p className={classes.cat}><span>Where does mold grow?</span></p>
          <p className={classes.mbLg}>Mold can grow on almost any surface, but it thrives in warm areas with excessive moisture.
          This means that <b className={classes.b1}>mold commonly grows in places that are hard to see or access</b> -- inside walls and under floors, crawl spaces, heating ducts, etc...
          </p>

          <p className={classes.cat}><span>Why is it dangerous?</span></p>
          <p>
            Mold exposure can have a <b className={classes.b1}>number of negative effects on your home, your health,</b> <b>and even your pets. </b>
            Since the infestation is not always visible, <b className={classes.b1}>people often mistake signs of mold exposure for things like seasonal/pet allergies, a cold, or a 
            sinus infection </b> because the symptoms can be very similar to those of other environmental allergens. 
          </p>
            
          <p>
            That is why the <b className={classes.b1}>certified experts at Gulf Coast Mold Detection</b> suggest having your home and/or business regularly inspected 
            by professionals for signs of mold. 
          </p>
        </div>
      </div>

      <div className={classes.cSympt}>
        <div className={classes.med2Def}>
          <div className={classes.image}><img src={wall} alt="alt ph" className={classes.imgResponsive} /></div>

          <div className={classes.symptoms}>
            <p className={classes.csTitle}><span>Mold Exposure</span><br/> Common Symptoms</p>
            <ul>
              <li>Running nose</li>
              <li>Constant sinus congestion</li>
              <li>Constant sneezing</li>
              <li>Coughing</li>
              <li>Watery, red, and itchy eyes</li>
              <li>Strong headaches/migraines</li>
              <li>Sore throat</li>
              <li>Wheezing/shortness of breath</li>
              <li>Asthma attacks</li>
              <li>Tightness feeling in the chest</li>
            </ul>
          </div>
           
          <div className={classes.info}>
            <p>You will begin experiencing these symptoms within hours of being in a home with a mold infection problem, depending on the type of mold, and the levels of mold 
              spores in the air. Symptoms will only get worse with prolonged exposure. When you realize that your health problems are a direct result of a mold infestation, 
              it allows you to act quickly to resolve the situation before these issues become more serious.
            </p>
          </div>
        </div>
      </div>

  
      <div className={classes.lcSymptB}>
        <div className={classes.medDef}>
          <div className={classes.info}><p>Less common symptoms of<br/> prolonged mold exposure:</p></div>

          <ul className={classes.right}>
            <li>Skin rashes</li>
            <li>Hives</li>
            <li>Depression</li>
            <li>Fatigue</li>
            <li>Memory loss</li>
            <li>Joint pain</li>
            <li>Hair loss</li>
            <li>Hearing loss</li>
          </ul>

          <ul className={classes.left}>
            <li>Pulmonary Hemorrhage</li>
            <li>Lack of concentration</li>
            <li>Digestive issues</li>
            <li>Dental problems</li>
            <li>Respiratory infections like<br/> pneumonia or bronchitis</li>
            <li>Tingling and/or numbness<br/> in your hands and/or feet</li>
          </ul>
        </div>
      </div>
      
      <div className={classes.types}>
        <div className={classes.med2Def}>
          <div className={classes.top}>
            <h3>Common Species of Toxic Mold</h3>
            <p>While mold is found virtually everywhere you go and is not always harmful, there are certain types of mold or fungi that can pose 
            a serious health risk if they are present in your home. The list below contains a few of the most common species of toxic mold that can grow inside homes, 
            wherever there are wet surfaces or water damage.</p> {/* and a few tips for what to do if you think there might be harmful mold in your home.  */}
          </div>
        </div>
        
        <div className={classes.medDef}>
          <div className={classes.typeNames}>
            <div className={[classes.oneType, classes.illx, classes.one].join(' ')}>
              <p className={classes.tTitle}>Stachybotrys</p>
              <p>Commonly referred to as “black mold,” stachybotrys is one of the most dangerous types of mold and can cause flu-like symptoms, diarrhea, headaches, memory loss and 
              severe respiratory damage. Because their lungs are still developing, children are at an even greater risk for health problems associated with exposure to black mold.
              As its nickname suggests, black mold is dark in color, although it can also be grey or dark green. It typically has a very musty or mildewy odor and is found in very 
              damp areas of homes and other buildings.</p>
            </div>

            <div className={[classes.oneType, classes.ilrx, classes.two].join(' ')}>
              <p className={classes.tTitle}>Chaetomium</p>
              <p>Chaetomium is most commonly found in homes that have water damage, as it thrives in wet, dark environments such as drywall, wallpaper, baseboards and carpets. 
              This type of mold is similar to black mold and sometimes even shares the same environments. Along with general signs of allergies such as red, watery eyes and 
              trouble breathing, chaetomium spores can also cause neurological damage and certain autoimmune diseases.</p>
            </div>

            <div className={[classes.oneType, classes.reg, classes.three].join(' ')}>
              <p className={classes.tTitle}>Aspergillus</p>
              <p>Although aspergillus spores are present in the air that many of us breathe every day, this type of mold can be dangerous to people with comprised immune systems. 
              Exposure to aspergillus may cause allergic reactions, lung infections and a specific type of infection known as aspergillosis.</p>
            </div>

            <div className={[classes.oneType, classes.reg, classes.four].join(' ')}>
              <p className={classes.tTitle}>Penicillium</p>
              <p>Like aspergillus, penicillium is abundant in many everyday environments, but if trapped inside your home, it can cause asthmatic symptoms, bronchitis and other 
              respiratory problems.</p>
            </div>

            <div className={classes.clearfix} />

            <div className={[classes.oneType, classes.reg, classes.five].join(' ')}>
              <p className={classes.tTitle}>Fusarium</p>
              <p>Fusarium is a type of mold that is most commonly found in plant debris and soil, although it can also be found in very damp areas of your home such as underneath 
              carpeting, inside drywall or foam insulation and in humidifier pans and HVAC systems.
              Although quite rare, a serious eye infection called fusarium keratitis can result from exposure to fusarium spores.</p>
            </div>

            <div className={[classes.oneType, classes.reg, classes.six].join(' ')}>
              <p className={classes.tTitle}>Alternaria</p>
              <p>A well-known allergy-causing mold, alternaria is present in high amounts in many climate zones during the spring and summer. Although this type of mold is usually 
              not found in building materials, it is can be present in the air inside your home and may also be found in electric cables, cardboard, canvas and other textiles.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
export default health;