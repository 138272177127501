import React from "react";
// import Social from "../Social/Social";
import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import NavList from "../Header/Nav";
const classes = require('./Footer.module.scss');
const logo = require('../../assets/images/gcmdLogo.png');

const footer = (props) => {
  return (
    <div className={[classes.Footer, classes[props.FootType]].join(' ')}>
      <div className={classes.TopFt}>
        <div className={classes.med2Def}>
          <div className={[classes.ftColumn, classes.contact].join(' ')}>
            <p className={classes.cat}>Contact Us</p>
            <div className={classes.titleLine} /><div className={classes.clearfix} />
            <p className={classes.info}><Phone /></p>
            <p className={classes.info}><Email /></p>
            <p className={classes.info}>
              <b>Monday - Friday:</b> 8am - 5pm<br/> 
              <b> Saturday:</b> 10am - 4pm
            </p>

            <a className={classes.fb} href="https://www.facebook.com/GulfCoastMoldDetection" target="_blank" rel="noopener noreferrer">
              <span className={classes.SocialIcon} aria-label="Gulf Coast Mold Detection Facebook Link" uk-icon="icon: facebook"></span>
            </a>
          </div>

          <Link to="/" className={[classes.ftColumn, classes.logo].join(' ')}><img src={logo} alt="Gulf Coast Mold Detection Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link>

          <div className={[classes.ftColumn, classes.blurb].join(' ')}>
            <p>Gulf Coast Mold Detection offers <b>professional, comprehensive</b> mold inspections throughout the <b>Gulf Coast of Alabama and Florida. </b>
              Our team is <b>expertly trained</b> and <b>highly qualified</b> to detect signs of mold in your home or office building.</p>
            <div className={classes.btnRow}>
            <button className={classes.gcmLink} type="button" data-uk-toggle="target: #modal-request">Request Service</button>
            </div>
            
            <Link to="/" className={classes.logoSm}><img src={logo} alt="Gulf Coast Mold Detection Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link>
          </div>
        </div>
      </div>

      {/* <div className={classes.mobFtNav}><NavList NavLocation="Footer" /></div> */}
      <div className={["uk-light", classes.Accordion, classes.mobFtNav].join(' ')}>
        <ul className={classes.accordList} data-uk-accordion>
          <li className={classes.fullLi}>
            <button className={["uk-accordion-title", classes.custTitle].join(' ')}>Menu</button>
            <div className={["uk-accordion-content", classes.custContent].join(' ')}>
              <NavList NavLocation="Footer" />
            </div>
          </li>
        </ul>        
      </div>



      <div className={classes.copyHs}>
        <div className={classes.insideXl}>
          <p className={classes.copy}>© {(new Date().getFullYear())} Gulf Coast Mold Detection. All rights reserved. <br/>
          <span className={classes.hs}> Design by <a href="https://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></span></p> 
        </div>
      </div>
    </div>
  );
}

export default footer;