import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import Certification from "../../components/CertificationSlide/CertificationSlide";
const classes = require('./HomeFull.module.scss');
const intro1 = require('../../assets/images/modern-wall-damageXl.jpg');
const introM = require('../../assets/images/modern-wall-damage.jpg');
const whyImg1 = require('../../assets/images/owner-damage.jpg');

const homeFull = () => {
  return (
    <div className={classes.HomeFull}>
      <Helmet>
        <title>Professional Mold inspections along the Gulf Coast of Al and Fl</title>
        <meta name="description" content="Gulf Coast Mold Detection provides certified, professional mold inspection services throughout Mobile and Baldwin County, AL, and Pensacola, FL." />
      </Helmet>
       
      <div className={classes.introSec}>
        <div className={classes.inside}>
          <div className={classes.left}>
            <div className={classes.text}>
              <h1><span>Certified, professional</span> mold detection
              <br className={classes.br1} /> services throughout <b>Mobile</b> and 
              <br className={classes.br2} /> 
              <b> Baldwin County, AL</b> and <b>Pensacola, FL</b></h1>
              
              <h2 className={classes.lead}>We've got your mold controlled!</h2>
              <h3>Contact <b>Gulf Coast Mold Detection </b><br/> today for a quote!</h3>
              <Phone where="homeIntro" /> <Email where="homeIntro" />
            </div>
          </div>
          
          <div className={classes.right}>
            <img src={intro1} alt="Wall damage caused by mold and moisture" className={[classes.imgResponsive, classes.icImg1, classes.fw].join(' ')} />
            <img src={introM} alt="Wall damage caused by mold and moisture - mobile version" className={[classes.imgResponsive, classes.icImg1, classes.mobile].join(' ')} />
            <div className={[classes.innerRight, "uk-height-large uk-background-cover uk-light uk-flex"].join(' ')} data-uk-parallax="bgy: -200"></div>
          </div>
        </div>
      </div>

      <div className={classes.hs2}>
        <div className={classes.medDef}>
          <p className={classes.cat}>Why choose Gulf Coast Mold Detection?</p>
          <p>
            Gulf Coast Mold Detection offers <b>professional, comprehensive mold inspections</b> throughout the Gulf Coast of Alabama and Florida. Our team is 
            <b> expertly trained</b> and <b>highly qualified</b> to detect signs of mold in your home or office building.
          </p>
          <p>
            <span>We have <b>over 15 years of experience,</b> and have <b>handled thousands of insurance claims.</b></span> So you can trust us to provide you with all of 
            the information you need to establish an effective plan for remediation and prevention.
          </p>

          <div className={classes.btnRow}>
            <Link to="/about" className={classes.gcmLink}>More About Us</Link>
            <div className={classes.clearfix} />
            <button className={classes.gcmLink} type="button" data-uk-toggle="target: #modal-request">Request Service</button>
          </div>
        </div>
      </div>
    
      <div className={classes.hsCert}><Certification Section="HomePage" /></div>

      <div className={classes.hsWhy}>
        <div className={classes.medDef}>
          <div className={classes.row}>
            <div className={classes.text}>
              <p className={[classes.cat, classes.first].join(' ')}>Why does mold matter?</p>
              <p>Mold exposure can have a number of negative effects on your home, your health, and even your pets.</p>
              <p className={classes.btmp}>Mild symptoms, such as coughing, congestion, and headaches can appear within hours of exposure, depending on the type of mold, 
                and the levels of mold spores in the air.
                Prolonged exposure can lead to more serious health concerns, like rashes/hives, digestive issues, 
                and in some cases, much worse.
              </p>
              <div className={classes.btnRow}>
                <Link to="/health" className={classes.textLink}>
                  Learn More About Mold and Health <span aria-label="Gulf Coast Mold Detection Link to Health Page" uk-icon="icon: arrow-right" className={classes.linkBtn} />
                </Link>
              </div>

              <p className={classes.cat}>Why do mold inspections matter?</p>
              <p>Mold thrives in warm areas with excessive moisture, so it commonly grows in places that are hard to see or access -- inside walls, crawl spaces, 
                heating ducts, etc... Since mold infestations are not always visible, people often mistake their resulting health problems for things like seasonal 
                allergies or sinus infections. The mold remains undiscovered, and their symptoms continue to get worse over time.</p>
              <p className={classes.btmp}>That is why the certified experts at Gulf Coast Mold Detection suggest having your home and/or business regularly inspected by 
                professionals for signs of mold.
              </p>
              <div className={classes.btnRow}>
                <Link to="/faq" className={classes.textLink}>
                  Gulf Coast Mold Detection FAQ's <span aria-label="Gulf Coast Mold Detection Link to FAQ Page" uk-icon="icon: arrow-right" className={classes.linkBtn} />
                </Link>
              </div>
            </div>
            
            <div className={classes.image}>
              <img src={whyImg1} alt="Home owner assessing mold damage" className={[classes.imgResponsive, classes.img1].join(' ')} />
              <div className={classes.textB}>
                <p className={classes.cat}>Put us to work for you!</p>
                <p>Mold spreads fast, but we work
                  <br className={classes.one}/> faster! If you suspect you may have 
                  <br className={classes.two}/> a mold problem in your home or 
                  <br className={classes.three}/> office building, <Link to="/contact">contact Gulf Coast 
                  <br className={classes.four}/> Mold Detection today</Link> to schedule a comprehensive mold inspection.</p>
                <p><Phone/></p>
                <p className={classes.lastp}><Email /></p>
                <button className={classes.gcmLink} type="button" data-uk-toggle="target: #modal-request">Request Service</button>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.imageAbs}>
          <div className={[classes.inner, "uk-height-large uk-background-cover uk-light uk-flex"].join(' ')} data-uk-parallax="bgy: -200"></div>
        </div> 
      </div>
    </div>
  );
}
export default homeFull;