import React, {Component} from "react";
import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
const classes = require('./Header.module.scss');
const styles = require('../Footer/Footer.module.scss');

class NavList extends Component {
  render() {
    let styleParent = classes;
    if (this.props.NavLocation === 'Footer') {
      styleParent = styles;
    }

    return (
      <ul className={styleParent.SideNav}>
        <li className={styleParent.navItem}><Link to="/">Home</Link></li>
        <li className={styleParent.navItem}><Link to="/about">About</Link></li>
        <li className={styleParent.navItem}><Link to="/health">Mold &amp; Your Health</Link></li>
        <li className={styleParent.navItem}><Link to="/faq">FAQ's &amp; Tips</Link></li>
        <li className={styleParent.navItem}><Link to="/contact">Contact</Link></li>
        <div className={styleParent.clearfix} />
        <li className={[styleParent.navItem, styleParent.phoneLi].join(' ')}><Phone /></li>
        <li className={[styleParent.navItem, styleParent.emailLi].join(' ')}><Email /></li>
        <li className={[styleParent.navItem, styleParent.mnBtn].join(' ')}><button type="button" className={styleParent.gcmLink} data-uk-toggle="target: #modal-request">Request Service</button></li>
      </ul>
    );
  }
}

export default NavList;