import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
// import { Link } from "react-router-dom";
// import Phone from "../Shared/Phone";
// import Email from "../Shared/Email";
import NavList from "./Nav";
const classes = require('./Header.module.scss');

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
        <NavList />
    </div>
  );

  return (
    <div className={classes.NavDrawer}>
      <Button onClick={toggleDrawer('left', true)}>
        <MenuIcon />
      </Button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;