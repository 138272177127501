import React from "react";
import { Link } from "react-router-dom";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import Drawer from "./Drawer";
const classes = require('./Header.module.scss');
const logo = require('../../assets/images/gcmdLogo.png');

const header = (props) => {
  return (
    <header className={[classes.Header, classes[props.HeadType]].join(' ')}>
      <div className={classes.insideXl}>
        <nav className={classes.navMm}>          
          <Link to="/" className={[classes.logoBlk, classes.regLogo].join(' ')}><img src={logo} alt="Gulf Coast Mold Detection Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link>

          <ul className={classes.navBarCustom}>
            <li className={classes.navItem}><Link to="/about">About Us</Link></li>

            <li className={[classes.navItem, classes.withDrop].join(' ')}><Link to="#">Information</Link>
              <div className={classes.custDrop}>
                <ul className={classes.navUL}>
                  <li className={classes.navItem}><Link to="/faq">FAQ's &amp; Tips</Link></li>
                  <li className={classes.navItem}><Link to="/health">Mold &amp; Your Health</Link></li>
                </ul>
              </div>
            </li>

            {/* <li className={classes.navItem}><Link to="/contact">Contact</Link></li> */}
            <li className={[classes.navItem, classes.withDrop].join(' ')}><Link to="/contact">Contact</Link>
              <div className={[classes.custDrop, classes.contactDrop].join(' ')}>
                <ul className={classes.navUL}>
                  <li className={classes.navItem}><Link to="/contact">Contact Us</Link></li>
                  <li className={classes.navItem}><Email /></li>
                  <li className={classes.navItem}><Phone /></li>
                </ul>
              </div>
            </li>
            
            
            <div className={classes.clearfix} />
            <li className={[classes.navItem, classes.phoneLi].join(' ')}><Phone /></li>
            <li className={[classes.navItem, classes.reqLi].join(' ')}>
              <button type="button" data-uk-toggle="target: #modal-request">Request Service</button>
            </li>
          </ul>

          <div className={classes.mobNav}><Drawer /></div>
        </nav>        
      </div>

      <div className={classes.mobileCTA}>
        <div className={classes.insideTw}>
          <div className={[classes.mcta, classes.req].join(' ')}>
            <button type="button" data-uk-toggle="target: #modal-request">Request Service</button>
          </div>
          <div className={[classes.mcta, classes.phone].join(' ')}><Phone /></div>
          <div className={[classes.mcta, classes.email].join(' ')}><Email /></div>
        </div>
      </div>
    </header>
  );
}

export default header;


