import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import Certification from "../../components/CertificationSlide/CertificationSlide";
const classes = require('./About.module.scss');
const technology = require('../../assets/images/infrared-scan.jpg');
const technologyB = require('../../assets/images/infrared-scanb.jpg');


const about = () => {
  return (
    <div className={classes.About}>
      <Helmet>
        <title>Professional Mold inspections | Gulf Coast Mold Detection</title>
        <meta name="description" content="Learn more about Gulf Coast Mold Detection and the certified, professional mold inspections we offer the Gulf Coast of Florida and Alabama." />
      </Helmet>

      <div className={[classes.PageHeader, classes.AboutHd].join(' ')}>
        <div className={classes.med2Def}>
          <h1>Gulf Coast Mold Detection provides professional <br className={classes.one}/>mold inspection services throughout
          <br className={classes.cust}/> <span className={classes.emphSmall}> Mobile, AL</span>,
          <br className={classes.two}/> <span className={classes.emphLong}>Baldwin County,</span> AL 
          and <span className={classes.emph}>Pensacola, FL</span>.</h1>
        </div>
      </div>

      <div className={classes.as2Inline}>
        <div className={classes.med2Def}>
          <div className={classes.left}>
            <h2>Our team is <b>expertly trained,</b><br/> and <b>highly experienced.</b></h2>
            <h3>Contact us today for a quote!</h3>
            <div className={classes.conRow}><Phone /> <Email /></div>
          </div>

          <div className={classes.right}>
            <p>
              If you have, or suspect that you have, a mold problem in your home or office building, 
              a comprehensive mold inspection from <b>Gulf Coast Mold Detection</b> will give you the answers you need. 
              {/* // A member of our team will perform a visual inspection of your property, as well as a comprehensive moisture analysis of all areas within the structure using the latest technology.  */}
            </p>
            <p>We have more than <b>15 years of experience,</b> and have handled <b>thousands of insurance claims. </b>
            Our team is <b>always up-to-date</b> on the current established guidelines and effective practices for mold testing, 
            use the <b>latest technology.</b></p>
          </div>
        </div>
      </div>

      <div className={classes.asGridStep}>
        <div className={classes.insideTw}>
          <div className={[classes.ptitle, "uk-height-large uk-background-cover uk-light uk-flex"].join(' ')} data-uk-parallax="bgy: -200">
            <p className={classes.plaxp}>Gulf Coast Mold Detection<br/> Inspection Procedure</p>
          </div>
        </div>

        <div className={classes.medDef}>
          <div className={[classes.mdStep, classes.one].join(' ')}>
            <p>A certified Gulf Coast Mold Detection specialist will arrive at the property to perform a mold inspection.
            Our technician will be equipped with an infrared camera, which will allow him or her to locate excessive moisture in hard-to-see areas, 
            such as behind walls, ceilings, and floors.</p>
          </div>

          <div className={[classes.mdStep, classes.two].join(' ')}>
            <p>Our technician will conduct a full visual assessment of the location, structure, and surrounding areas where the mold infestation was originally suspected.</p>
          </div>            

          <div className={[classes.mdStep, classes.three].join(' ')}>
            <p>Our technician will survey the humidity and temperature levels at the property.</p>
          </div>

          <div className={[classes.mdStep, classes.four].join(' ')}>
            <p>Our technician will conduct air quality samples inside and outside the property. 
            The outside air sample is used as a baseline to give the customer a better understanding of what the normal levels should look like in their 
            respective home or business.
            </p>
          </div>

          <div className={classes.imgRow}>
            <img src={technology} alt="Infrared camera for thermal imaging during mold inspection" className={[classes.imgResponsive, classes.imgAbs, classes.fw].join(' ')} />
            <img src={technologyB} alt="Mold inspection thermal imaging with infrared camera" className={[classes.imgResponsive, classes.imgAbs, classes.mobile].join(' ')} />
            
              <div className={[classes.mdStep, classes.five].join(' ')}>
                <p><b>If visible mold is found during the inspection,</b> our technician will take a swab or bio-tape sample of the actual mold, and send it to 
                the lab for further testing. <br/>
                <b>If moisture intrusion is found</b> behind a ceiling, wall, or flooring surface, our technician will use thermal imaging to catalogue the problematic locations.
                </p>
              </div>
              <div className={classes.clearfix}/>
              <div className={[classes.mdStep, classes.six].join(' ')}>
                <p>We will document and photograph any areas of concern, such as: visible mold, water damage, excessive moisture, and anything else that aids in our 
                investigation of the property.           
                We will send the customer a comprehensive report that includes our findings. <b>This report can be used for legal purposes.</b>
                </p>
            </div>
          </div>



          <div className={[classes.mdStep, classes.svn].join(' ')}>
            <p>Gulf Coast Mold Detection will contact the customer with a detailed explanation of the lab's findings, and supply them with a complete mold remediation
            protocol and lab report based on the IICRC S520 and New York City Department of Health and Mental Hygiene.</p> 
          </div>
           
          <div className={[classes.mdStep, classes.eight].join(' ')}>
            <p>We offer clearance testing after the mold remediation is complete. We will provide our client with a comprehensive report that contains the clearance 
            testing results.<br/>
            {/* AWK ? */}
            <b>In the event that mold remediation is not necessary,</b> we still provide our client with both the clearance report and the lab report findings.</p>
            <p><b className={classes.green}>To avoid any conflicts of interest, we offer mold inspections only. We DO NOT perform any remediation services.</b></p>
          </div>
        </div>
      </div>

      <div className={classes.qual}>
        <div className={classes.insideNrw}>
          <p className={classes.lead}>Certified for Your Peace of Mind</p>
          <p>Our experience and credentials let our customers know that qualified professionals are working toward their best interests.</p>
        </div>
      </div>

      <Certification />
    </div>
  );
}
export default about;