import React, {Component} from 'react';
import { withRouter, Route, Switch } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import classes from './App.module.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Contact from './hoc/Contact/Contact';
import FAQ from './hoc/FAQ/FAQ';
import HomeFull from './hoc/HomeFull/HomeFull';
import Health from './hoc/Health/Health';
import About from './hoc/About/About';
import RequestModal from './components/RequestModal/RequestModal';
// npm install --save typescript @types/node @types/react @types/react-dom @types/jest

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route exact={true} path="/" component={HomeFull} />
        <Route path="/contact" component={Contact} />
        <Route path="/faq" component={FAQ} />
        <Route path="/health" component={Health} />
        <Route path="/about" component={About} />
      </Switch>
    );


    const HeadCase = () => {
      if (window.location.pathname === '/') {
        return (
          <Header HeadType="homeHead" />
        );
      }
      return (
        <Header HeadType="reg" />
      );
    }
    const SneakyHead = withRouter(HeadCase);

    return (
      <div className={classes.gcmdWrap}>
        <SneakyHead />
        <main className={classes.Content}>
          {routes}
        </main>
        <Footer FootType="default" />
        <RequestModal />

        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', backgroundColor: '#5eb445', width: '28px', padding: '0px 5px 0px' }}
          EasingType='linear'
        />
      </div>
    );
  }
}
export default App;
