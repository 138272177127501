import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Accordion from "../../components/Accordion/Accordion";
import Certification from "../../components/CertificationSlide/CertificationSlide";
const classes = require('./FAQ.module.scss');
// const whyImg2 = require('../../assets/images/photo here?');

const WhatIsMold = (
  <div id="sw1" key="WhatIsMold" className={classes.faqCat}>
    <div className={classes.text}>
      <p>Mold is a fungus. It has tens of thousands of different species, and needs moisture, oxygen, and an organic food source (like wood or dust) to grow.</p>
      <p>Mold grows best in damp, warm, and dark environments. It is a normal part of the outdoor environment. 
        There will always be some mold both outside and inside your house.</p>      
    </div>
  </div>
);

const whyMoldGrows = (
  <div id="sw2" key="whyMoldGrows" className={classes.faqCat}>
    <div className={classes.text}>
      <p>Mold enters your home as tiny spores. The spores need moisture to begin growing, digesting, and destroying. Mold can grow on almost any surface, including wood, 
      ceiling tiles, wallpaper, paints, carpet, sheet rock, and insulation.</p>
      <p>Mold grows best when there is lots of moisture, like that from a leaky roof, high humidity, or flood. 
      There is no way to get rid of all molds and mold spores from your home, but you can control mold growth by keeping your home dry.</p>     
    </div>
  </div>
);

const moldSick = (
  <div id="sw3" key="moldSick" className={classes.faqCat}>
    <div className={classes.text}>
      <p>Yes! Mold causes allergic reactions in many people —- eye or skin irritation, wheezing, nasal stuffiness, and/or coughing.</p>
      <p>Some people are more sensitive to mold than others. Young children, elderly people, people with immune compromised conditions (HIV/AIDS, lupus, etc), and people 
      with severe allergies or asthma should be especially careful to avoid mold.</p>
      <p> Mold can worsen asthma and allergies. People with obstructive lung disease or other 
      chronic lung problems can develop mold infections in their lungs.</p>
    </div>
  </div>
);

const allergenic = (
  <div id="sw4" key="allergenic" className={classes.faqCat}>
    <div className={classes.text}>
      <p>Not all molds are allergenic. As with pollen, certain mold spores are allergenic because they are small enough to float in the air, and evade the protective 
      mechanisms of the respiratory system.</p> 
      <p>The most common allergenic, indoor molds include Alternaria, Aspergillus, Chaetomium, Cladosporium, Fusarium, Mucor, 
      Penicillium, and Stachybotrys.</p>
    </div>
  </div>
);

const moldAffects = (
  <div id="sw5" key="moldAffects" className={classes.faqCat}>
    <div className={classes.text}>
      <p>No. Some individuals have a genetic makeup that puts them at risk for developing allergies to mold. </p>
      <p>People who have an allergy to mold, especially if they also have asthma, can become ill from exposure to just a small amount. 
        Individuals also seem to be quite different in their response to exposure to the toxic chemicals that some molds release.</p> 
        <p>These differences between individuals contribute to the difficult question of determining safe exposure limits for mold.</p>
    </div>
  </div>
);

const blackMold = (
  <div id="sw6" key="blackMold" className={classes.faqCat}>
    <div className={classes.text}>
      <p><b>Most molds are not toxic. </b> 
      The most common types of indoor molds in this area (Aspergillus, Penicillium, Cladosporium) do not produce toxins, and many molds are black.
      So don’t be scared just because you see black mold in your house!</p>
      <p>There are, however, some species of mold that can produce toxins. There also may be a link between these molds and serious health effects, like memory 
      loss and severe illness in infants.</p>
      <p>It should be noted that, even though "toxic" molds are not common, living in a home with high levels of any kind of mold is not a good idea. 
      Mold can eventually rot the wood components of the house, and make it structurally unsound. It also eats through drywall paper. So if you build on top of mold, 
      you may have to replace your drywall in just a few years. Lastly, living around mold is simply not healthy. 
      Not cleaning the mold in your house makes you and your family more likely to get sick.</p>
    </div>
  </div>
);

const moldControl = (
  <div id="sw7" key="moldControl" className={classes.faqCat}>
    <div className={classes.text}>
      <p>In practical terms, just as you cannot kill every single dust mite in your home, you cannot get rid of every single mold spore. However, with environmental control, 
      you can get rid of your allergy symptoms.</p>
      <p>Repair any leaks or problems leading to water build-up in your home immediately, and remove all materials that have been damaged by water 
      (this includes wood, wall paper, carpet, etc...). Keep exterior surfaces of your home properly sealed, and avoid piling wood or leaves near your home 
      as they collect moisture.</p> 
      <p>Scour sinks and tubs at least once a month, and wash out garbage receptacles frequently. Getting rid of indoor plants can also reduce the number of mold spores in the air.</p>

      <p>Make sure your home is adequately ventilated. Hidden mold often grows inside HVAC systems. An allergy relief vent filter will trap the mold before it reaches you.
      A HEPA air purifier will remove a minimum of 99.97% of all mold spores in your home, and a HEPA filtered vacuum cleaner will suck up microscopic mold spores deep in 
      your carpet, so that you can dispose of them. Monitor the humidity in your home with a hygrometer, or humidity gauge. Keep your home’s humidity between 40 and 50 percent. 
      Most importantly, use a dehumidifier to remove excess moisture from the air and control humidity.</p>
    </div>
  </div>
);

const moldTest = (
  <div id="sw8" key="moldTest" className={classes.faqCat}>
    <div className={classes.text}>
      <p>If you are experiencing allergic symptoms, such as stuffy head, headaches, scratchy throat, or runny nose, and are not sure why.</p>
      <p>If you think you see mold, but are not completely certain it is mold.</p>
      <p>If you smell a musty odor, but don’t see any obvious mold.</p>
      <p>If there have been plumbing leaks, flooding, or other water issues in your home or office.</p>
      <p>If you want/need air testing after mold removal has been done (by you or a professional), to check whether mold levels have normalized.</p>
      <p>If you are a buyer or seller in a real estate transaction, and need evidence as to whether or not airborne mold levels are normalized.</p>
      <p>If you are a landlord or tenant, and need evidence as to whether or not there is a mold problem.</p>
      <p>If you are looking for a general assessment of your indoor air quality to make sure your family is breathing high quality, clean air in your home.</p>
      <p>If you are occupying a house that was previously unoccupied for a long period.</p>
    </div>
  </div>
);

const serviceAreas = (
  <div id="sw9" key="serviceAreas" className={classes.faqCat}>
    <div className={classes.text}>
      <p>Gulf Coast Mold Detection provides certified, professional mold inspection services throughout the areas of
        Mobile and Baldwin County, Alabama, and Pensacola, Florida.</p>
    </div>
  </div>
);

const qualifications = (
  <div id="sw10" key="qualifications" className={classes.faqCat}>
    <div className={classes.text}>
      <p>Gulf Coast Mold Detection has been performing inspections since 2003, including thousands of insurance claims.
        We hold many different certifications (listed below), and we require extensive training for all of our technicians.
      </p>
      <Certification Section="faq" />
    </div>
  </div>
);

const gcmdServices = (
  <div id="sw11" key="gcmdServices" className={classes.faqCat}>
    <div className={classes.text}>
      <p><b className={classes.green}>To avoid any conflicts of interest, we offer mold inspections only. We DO NOT perform any remediation services.</b></p>
    </div>
  </div>
);


const moldProTipTitle = (
  <span><b>TIP:</b> Trust the Mold Inspector, not the Home Inspector</span>
)
  const moldProTip = (
  <div id="sw101" key="moldProTip" className={classes.faqCat}>
    <div className={classes.text}>
      <p>Part of your home-buying process is hiring a home inspector to walk through the house, and identify any potential problems before you move in. 
      However, most home inspectors are not skilled at identifying mold problems. Mold can be hidden away in moist areas, like underneath baseboards or carpet, 
      or lurking behind a paint job.</p>
      <p>Even when a home inspector can identify potential conditions that encourage mold growth, they cannot definitively identify the presence and type of 
      mold that is growing, as a mold inspector can. If you are worried about the cost of a mold inspector, consider that if the home does have a large amount of mold when,
      it is very likely that you will have to immediately move out and sink tens of thousands of dollars into repairs.</p>
      </div>
  </div>
);

const noDIYTitle = (
  <span><b>TIP:</b> Don't try to do it yourself</span>
)
const noDIY = (
  <div id="sw102" key="noDIY" className={classes.faqCat}>
    <div className={classes.text}>
      <p>Many people take it upon themselves to collect samples of their own mold because it can be far less expensive than hiring a professional mold inspector. 
      If you choose to do it yourself, however, you will not be able to get samples from all of the places where mold could be growing undetected.</p>
      <p>Gulf Coast Mold Detection is well informed on the current established guidelines and effective practices for mold testing, including the types of mold that 
      hide within walls, floors, attics, and basements. It is very important to remember that mold grows naturally, and therefore only a professional mold inspection company
      can determine if the levels of indoor mold exceed the normal amount.</p>
    </div>
  </div>
);


const accItems = [
  {id: 9, switchId1: 'sw9', switchId2: 'sw29', title: 'Where areas do you service?', content: [serviceAreas], open: "uk-open"},
  {id: 11, switchId1: 'sw11', switchId2: 'sw31', title: 'Do you offer mold remediation services?', content: [gcmdServices]},
  {id: 10, switchId1: 'sw10', switchId2: 'sw30', title: 'What are your qualifications?', content: [qualifications]},
  {id: 1, switchId1: 'sw1', switchId2: 'sw21', title: 'What is mold?', content: [WhatIsMold]},
  {id: 2, switchId1: 'sw2', switchId2: 'sw22', title: 'What makes mold grow in my home?', content: [whyMoldGrows]},
  {id: 3, switchId1: 'sw3', switchId2: 'sw23', title: 'Can mold make me sick?', content: [moldSick]},
  {id: 4, switchId1: 'sw4', switchId2: 'sw24', title: 'Which kinds of mold are allergenic?', content: [allergenic]},
  {id: 5, switchId1: 'sw5', switchId2: 'sw25', title: 'Does mold affect everyone the same way?', content: [moldAffects]},
  {id: 6, switchId1: 'sw6', switchId2: 'sw26', title: 'What about "toxic black mold?"', content: [blackMold]},
  {id: 7, switchId1: 'sw7', switchId2: 'sw27', title: 'How can I control the mold growth in my home?', content: [moldControl]},
  {id: 8, switchId1: 'sw8', switchId2: 'sw28', title: 'When should I test my home or business for mold?', content: [moldTest]},
  {id: 101, switchId1: 'sw101', switchId2: 'sw301', title: [moldProTipTitle], content: [moldProTip]},
  {id: 102, switchId1: 'sw102', switchId2: 'sw302', title: [noDIYTitle], content: [noDIY]},
];
// altClass, altClass2

const qList = accItems.map((accItem) =>
  <li key={accItem.id} className={classes.oen}>
    <a className={classes.b} href={["#" + accItem.switchId1]} data-uk-scroll>{accItem.title}</a>
  </li> 
);

const faq = () => {
  return (
    <div className={classes.FAQ}>
      <Helmet>
        <title>Gulf Coast Mold Detection FAQs</title>
        <meta name="description" content="Read through Gulf Coast Mold Detection's faq's to learn more about the services we provide, and why mold inspections matter." />
      </Helmet>

      <div className={[classes.PageHeader, classes.FaqHd].join(' ')}>
        <div className={classes.med2Def}>
          <h1>
            Read through our list of <span className={classes.emphCustom}>FAQ's</span> for more information about 
            Gulf Coast Mold Detection.<br/>
            <Link to="/contact"><span className={classes.emphLong}> Contact us</span></Link> if you have a question that isn't answered on this page.
          </h1>          
        </div>
      </div>

      <div className={classes.colorFaq} id="question-wrap" data-uk-grid>
        <div className={[classes.medDef, classes.float].join(' ')}>
          <div className={classes.questionBlk}>
            <ul className={classes.quests} data-uk-sticky="offset:50; bottom: #question-wrap;">
              {qList}
            </ul>
          </div>
          
          <div className={classes.qList}>
            <Accordion accItems={accItems} altClass="sizeColor" altClass2="openLgAcc" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default faq;